import styles from "./LandingPage.module.css";
import { useState, useEffect } from "react";
import WalcomePart from "./WalcomePart";
import Footer from "./Footer";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { publisherOrAuthor } from "../../state";
import { useRecoilState } from "recoil";
import HowItWorksAuthors from "./HowItWorksAuthors";
import HowItWorksPublishers from "./HowItWorksPublishers";
import PlatformfeaturesAuthors from "./PlatformfeaturesAuthors";
import PlatformfeaturesPublishers from "./PlatformfeaturesPublishers";
import logo from "../../images/logo.svg";
import Illustartionn from "../../images/Illustartion.svg";

import manu from "../../images/manu.svg";
import ContactUs from "./ContactUs";

const LandingPage = (props) => {
  const [option, setOption] = useRecoilState(publisherOrAuthor);
  const [alertType, setAlertType] = useState("success");

  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("page");
    if (page === "publishers") {
      setOption("publishers");
    } else {
      setOption("authors");
    }
  }, [setOption]);

  return (
    <div className={styles.LandingPage}>
      {showSuccess && (
        <div
          className={`${styles.successMessage} ${styles.successMessageLeft}`}
        >
          <Stack sx={{ width: "100%" }} spacing={2}>
            {alertType === "error" && (
              <Alert severity="error">
                Please fill in all the required details
              </Alert>
            )}
            {alertType === "success" && (
              <Alert severity="success">
                {" "}
                Your message has been sent successfully!
              </Alert>
            )}
          </Stack>
        </div>
      )}

      <div className={styles.WalcomePart}>
        <WalcomePart />
      </div>

      {option === "authors" ? <HowItWorksAuthors /> : <HowItWorksPublishers />}

      <div className={styles.Platformfeatures}>
        {option === "authors" ? (
          <PlatformfeaturesAuthors />
        ) : (
          <PlatformfeaturesPublishers />
        )}
      </div>

      <div className={styles.ProudMember}>
        <div className={styles.ProudMemberTitle}>Proud member of</div>
        <div className={styles.ProudMemberImages}>
          <img src={`${process.env.PUBLIC_URL}/pm_ibpa.png`} alt="ibpa" />

          <img src={`${process.env.PUBLIC_URL}/pm_ipg.png`} alt="ipg" />
        </div>
      </div>

      <div className={styles.ContactUs}>
        <ContactUs
          showSuccessMessage={showSuccessMessage}
          setAlertType={setAlertType}
        />
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;

// import styles from './LandingPage.module.css'
// import { useState } from 'react';
// import WalcomePart from './WalcomePart';
// import Footer from './Footer';
// import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';

// import ContactUs from './ContactUs';

// const LandingPage = (props) => {

//     const [option, setOption] = useState('publishers');

//     const [showSuccess, setShowSuccess] = useState(true);

//     const showSuccessMessage = (message) => {
//         setShowSuccess(true);
//         setTimeout(() => {
//             setShowSuccess(false);
//         }, 5000);
//     };

//     return (
//         <div className={styles.LandingPageroot}>
//             {showSuccess && (
//                 <div className={`${styles.successMessage} ${styles.successMessageLeft}`}>
//                     <Stack sx={{ width: '50%' }} spacing={2} >
//                         <Alert severity="success"> 'Your message has been sent successfully!'</Alert>
//                     </Stack>

//                 </div>
//             )}
//             <div className={styles.LandingPage}>

//                 <div className={styles.WalcomePart}>
//                     <WalcomePart option={option} setOption={setOption} />
//                 </div>
//                 <div className={styles.ContactUs}>
//                     <ContactUs option={option} setOption={setOption} showSuccessMessage={showSuccessMessage} />
//                 </div>
//                 <div className={styles.footer}>
//                     <Footer />
//                 </div>
//             </div>
//         </div>
//     );

// }

// export default LandingPage;
